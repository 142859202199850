import React from "react";
import "../styles/AddBanner.css";
import tshirtImage from "../assets/tshirt.png";

const AddBanner = () => {
  return (
    <div className="containerMain">
      <div className="text">
        <h1>Snap, Try, Slayyt!</h1>
        <p>Shop Insta cool trends, real style...</p>
      </div>
      <div className="image-container">
        <img src={tshirtImage} alt="T-shirt" className="tshirt-image" />
      </div>
    </div>
  );
};

export default AddBanner;
