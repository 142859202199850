import "../styles/Navbar.css";
import profileIcon from "../assets/person.svg";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import { useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { firstNameState, loginState } from "../store/atoms/phoneNumber";

function Navbar({ onOpenLogin, onSelectUser, showProfileBar }) {
  const auth = useAuth();
  const inputFile = useRef(null);
  const navigate = useNavigate();
  const firstName = useRecoilValue(firstNameState);
  const [login, setLogin] = useRecoilState(loginState);

  useEffect(() => {
    if (auth[0].token) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [setLogin, auth]);

  const openFile = () => {
    inputFile.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        navigate("/show_trends", { state: { im_url: reader.result } });
      };
      reader.onerror = () => {
        console.error("File reading error");
        toast.error("Something went wrong while uploading the image");
        // Handle the error, e.g., show an error message to the user
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <nav className="navbar">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="navbar-brand">Slayyt</div>
        <ToastContainer />
      </Link>
      <div className="navbar-center">
        <SearchBar onSelectUser={onSelectUser} />
        <div className="navbar-buttons">
          <div className="search-trend">
            <button onClick={openFile}>
              <span>Search Trend</span>
            </button>
          </div>
          <div className="login">
            {!login ? (
              <button onClick={onOpenLogin}>
                <span>Login</span>
                <img src={profileIcon} alt="Icon" />
              </button>
            ) : (
              <button onClick={showProfileBar}>
                <span>{firstName ? firstName : "Profile"}</span>
                <img src={profileIcon} alt="Icon" />
              </button>
            )}
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".jpeg, .png, .jpg, .heif, .heic"
      />
    </nav>
  );
}

export default Navbar;
