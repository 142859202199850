import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import instaIcon from "../assets/insta-icon.png";
import searchIcon from "../assets/search-icon.png";
import "../styles/Navbar.css";
import Loader from "./Loader";

const SearchBar = ({ onSelectUser }) => {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recents, setRecents] = useState([]);

  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem("recents")) || [];
    setRecents(storedItems);
  }, []);

  const loadOptions = async (inputValue) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/search_insta_user_by_keyword?keyword=${inputValue}`
      );
      if (response.data && response.data.data) {
        const options = response.data.data.map((user) => ({
          label: user.user.username,
          value: user.user.username,
          fullName: user.user.full_name,
          socialContext: user.user.social_context,
          profilePicUrl: user.user.profile_pic_url,
        }));
        setOptions(options);
        setShowDropdown(true);
        setLoading(false);
      } else {
        console.log(response.data.message);
        setOptions([]);
      }
    } catch (error) {
      console.error(error);
      setOptions([]);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  // eslint-disable-next-line
  const debouncedLoadOptions = useCallback(debounce(loadOptions, 1000), []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() === "") {
      setOptions([]);
      setShowDropdown(true);
    } else {
      debouncedLoadOptions(value);
    }
  };

  const handleOptionClick = (option) => {
    setQuery(option.label);
    setOptions([]);
    setShowDropdown(false);
    onSelectUser(option);

    // Update recents
    const updatedRecents = [
      option,
      ...recents.filter((item) => item.label !== option.label),
    ].slice(0, 3);

    setRecents(updatedRecents);
    localStorage.setItem("recents", JSON.stringify(updatedRecents));
  };

  const handleFocus = () => {
    if (query.trim() === "") setShowDropdown(true);
  };
  const looseFocus = () => {
    setShowDropdown(false);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <div className="relative flex items-center border-black w-96 mr-9 border-custom py-1">
      <button className="p-2 !cursor-default">
        <img src={instaIcon} alt="Instagram" />
      </button>
      <input
        type="text"
        placeholder="Search celebrities' Instagram"
        value={query}
        onFocus={handleFocus}
        onChange={handleInputChange}
        onBlur={looseFocus}
        className="p-2 w-full border-none outline-none font-aleo"
      />
      <button className="p-2 !cursor-default">
        <img src={searchIcon} alt="Search" />
      </button>
      {loading && <Loader />}
      {showDropdown && (options.length > 0 || recents.length > 0) && (
        <div className="absolute bg-white shadow-lg w-full z-10 top-full mt-4 border-black border-custom py-2">
          {query.trim() === "" && recents.length > 0 ? (
            <div>
              <div className="p-2 text-gray-600 font-bold font-aleo text-start">
                Recent Searches
              </div>
              {recents.map((recent, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionClick(recent)}
                  onMouseDown={handleMouseDown}
                  className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between font-aleo border border-gray-400 m-2"
                >
                  {recent.label}
                </div>
              ))}
            </div>
          ) : (
            options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option)}
                onMouseDown={handleMouseDown}
                className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between font-aleo border border-gray-400 m-2"
              >
                <div>
                  <div className="font-bold text-xl text-left">
                    {option.label}
                  </div>
                  <div className="text-sm text-gray-600 text-left	">
                    {option.fullName}
                  </div>
                </div>
                <div className="text-sm text-gray-600">
                  {option.socialContext}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
