import { atom } from "recoil";

const phoneNumberState = atom({
  key: "phoneNumberState",
  default: 0,
});

const firstNameState = atom({
  key: "firstNameState",
  default: "",
});

const loginState = atom({
  key: "loginState",
  default: false,
});

export { phoneNumberState, firstNameState, loginState };
