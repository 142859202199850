import React from "react";
import "../styles/Card.css";
// import iconImage from "../assets/search.png";

const howItWorksSteps = [
  {
    id: 1,
    title: "Search for your celebrity",
    description:
      "Use the search bar to find outfit details about a specific famous person.",
    bgColor: "#D6F2F7",
    fontColor: "#62C4D6",
  },
  {
    id: 2,
    title: "Discover fashion trends",
    description:
      "Find out fashion trends all influencers are following, so that you do not miss out.",
    bgColor: "#F0F7D6",
    fontColor: "#A6CE33",
  },
  {
    id: 3,
    title: "Click, buy and Slayyt",
    description: "Search on Slayyt and buy from the top shopping websites.",
    bgColor: "#FFEDED",
    fontColor: "#E376B7",
  },
];

const Card = () => {
  return (
    <div className="card-wrapper">
      {howItWorksSteps.map((step) => (
        <div className="card-container" key={step.id}>
          <div
            className="icon-container"
            style={{ backgroundColor: step.bgColor }}
          >
            {/* <img src={iconImage} alt="Icon" className="icon-image" /> */}
            <h4 style={{ color: step.fontColor }}>{step.id}</h4>
          </div>
          <div className="text-container">
            <h2 className="card-title">{step.title}</h2>
            <p className="card-description">{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
