import React from "react";
import { Link } from "react-router-dom";
import InstaProfileCard from "../components/InstaProfileCard";
import "../styles/InstaTopBar.css";

const InstaTopBar = ({ profile_pic, username, name }) => {
  return (
    <nav className="navbar-insta">
      <InstaProfileCard
        profile_image={profile_pic}
        username={username}
        name={name}
      />
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="navbar-brand-insta">Slayyt</div>
      </Link>
    </nav>
  );
};

export default InstaTopBar;
