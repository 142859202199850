import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import dummy from "../assets/web-latest.png";
import banners from "../assets/1-Banner.png";
import mobile from "../assets/mobile-3-latest.png";
import mobile2 from "../assets/banner-new.png";
import { useMediaQuery } from "react-responsive";
import "../styles/HeroBanner.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import DotLoader from "./DotLoader";

export default function HeroBanner() {
  const webBanner = [dummy, banners];
  const mobileBanner = [mobile, mobile2];

  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  const heroBanners = isTabletOrMobile ? mobileBanner : webBanner;

  return (
    <div className="hero-banner-container">
      <header className="hero-banner-header">
        <div className="text-center hero-banner-bg-image">
          {heroBanners.length > 0 ? (
            <Carousel>
              {heroBanners.map((banner, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 hero-banner-img"
                    src={banner}
                    alt={`Slide ${index}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <DotLoader />
          )}

          <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-white"></div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer />
    </div>
  );
}
