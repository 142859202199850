import React from "react";
import "../styles/LoginLoader.css";

const LoginLoader = () => {
  return (
    <div className="login-loader">
      <i />
    </div>
  );
};

export default LoginLoader;
