import React, { useRef } from "react";
import "../styles/UploadCsv.css";
import upload from "../assets/upload.svg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const UploadCsv = () => {
  const inputFile = useRef();
  const openFile = () => {
    inputFile.current.click();
  };

  const handfileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/api/v1/upload_csv`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (response.data.success) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="upload-root">
      <div className="upoad-container">
        <div className="upload-head">
          <div className="back-btn">
            <span>&lt;</span>
          </div>
          <div className="upload-title">Upload CSV</div>
        </div>
        <div className="upload-box" onClick={openFile}>
          <div className="upload-image">
            <img src={upload} alt="upload" />
          </div>
          <div className="upload-description">Select a csv file to upload</div>
        </div>
      </div>
      <input
        type="file"
        ref={inputFile}
        style={{ display: "none" }}
        accept=".csv"
        onChange={handfileUpload}
      />
      <ToastContainer />
    </div>
  );
};

export default UploadCsv;
