import React, { useCallback, useEffect, useRef, useState } from "react";
import Inputbox from "../components/Inputbox";
import mailIcon from "../assets/mail-icon.svg";
import callIcon from "../assets/login-icon.svg";
import nameIcon from "../assets/name-icon.svg";
import "../styles/ProfileSideBar.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { firstNameState, phoneNumberState } from "../store/atoms/phoneNumber";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import SaveLoader from "../components/SaveLoader";

const ProfileSideBar = ({ show, onClose, onLogout }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState(0);
  const [initialFirstName, setInitialFirstName] = useState("");
  const [initialLastName, setInitialLastName] = useState("");
  const [initialEmail, setInitialEmail] = useState("");
  const phoneNumber = useRecoilValue(phoneNumberState);
  const [edit, setEdit] = useState(true);
  const [saving, setSaving] = useState(false);
  const setFirstNameAtom = useSetRecoilState(firstNameState);
  const ref = useRef();

  const resetState = useCallback(() => {
    setEdit(true);
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setEmail(initialEmail);
  }, [initialEmail, initialFirstName, initialLastName]);

  const fetchProfileDetails = useCallback(async () => {
    try {
      if (phoneNumber !== 0) {
        setSaving(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/v1/get_profile_details`,
          {
            params: {
              phone_number: phoneNumber,
            },
          }
        );
        if (response.data.success) {
          const { first_name, last_name, email } = response.data.data;
          setEmail(email);
          setFirstName(first_name);
          setLastName(last_name);
          setInitialFirstName(first_name);
          setInitialLastName(last_name);
          setInitialEmail(email);
          setFirstNameAtom(first_name);
          setSaving(false);
        } else {
          setSaving(false);
          console.error(response.data.message);
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setSaving(false);
    }
  }, [phoneNumber, setFirstNameAtom]);

  const updateProfile = async () => {
    if (!firstName || !lastName || !email) {
      toast.error("Please fill out all fields.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/v1/update_profile`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: number,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setFirstNameAtom(firstName);
        setInitialFirstName(firstName);
        setInitialLastName(lastName);
        setInitialEmail(email);
        setEdit(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      resetState();
    }

    if (phoneNumber) setNumber(phoneNumber);
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, phoneNumber, resetState]);

  useEffect(() => {
    fetchProfileDetails();
  }, [fetchProfileDetails]);

  // if (!show) {
  //   return null;
  // }

  const onEdit = () => {
    if (edit) {
      setInitialFirstName(firstName);
      setInitialLastName(lastName);
      setInitialEmail(email);
    } else {
      setFirstName(initialFirstName);
      setLastName(initialLastName);
      setEmail(initialEmail);
    }
    setEdit(!edit);
  };

  useEffect(() => {
    const editBtn = ref.current;
    if (edit) {
      editBtn.style.width = "50%";
      editBtn.style.marginLeft = "auto";
      editBtn.style.removeProperty("flex");
    } else {
      editBtn.style.flex = 1;
      editBtn.style.removeProperty("width");
      editBtn.style.removeProperty("margin-left");
    }
  }, [edit]);

  return (
    <div className={`profile-root ${show ? "show" : ""}`}>
      <div className="profile-container">
        <div className="flex flex-row justify-content-between items-center">
          <div className="profile-box">
            <div className="arrow-profile" onClick={onClose}>
              <span>&lt;</span>
            </div>
            <div className="profile-title">Profile</div>
          </div>
          <div className="edit-save-box">
            <button className="edit-btn" onClick={onEdit} ref={ref}>
              {edit ? "Edit" : "Cancel"}
            </button>
            {!edit && (
              <button className="save-btn" onClick={updateProfile}>
                {saving ? <SaveLoader /> : "Save"}
              </button>
            )}
          </div>
        </div>
        <Inputbox
          icon={nameIcon}
          label={"First Name"}
          placeholder={"John"}
          onChange={(e) => setFirstName(e.target.value)}
          inputType={"text"}
          value={firstName}
          readOnly={edit}
        />
        <Inputbox
          icon={nameIcon}
          label={"Last Name"}
          placeholder={"Doe"}
          onChange={(e) => setLastName(e.target.value)}
          inputType={"text"}
          value={lastName}
          readOnly={edit}
        />
        <Inputbox
          icon={mailIcon}
          label={"Email"}
          placeholder={"john@yopmail.com"}
          onChange={(e) => setEmail(e.target.value)}
          inputType={"email"}
          value={email}
          readOnly={edit}
        />
        <Inputbox
          icon={callIcon}
          label={"Phone Number"}
          placeholder={"+91 9231231357"}
          inputType={"tel"}
          value={number}
          readOnly={true}
        />
        {edit && (
          <div className="logout-container">
            <button onClick={onLogout}>Logout</button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProfileSideBar;
