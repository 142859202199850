import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import "../styles/LoginDialog.css";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../context/auth";
import LoginLoader from "./LoginLoader";
// import "react-phone-input-2/lib/style.css";

const LoginDialog = ({ show, onClose }) => {
  const [number, setNumber] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState("number"); // 'number' or 'otp'
  const [countdown, setCountdown] = useState(20); // countdown time in seconds
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
      resetState();
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [show]);

  useEffect(() => {
    if (step === "otp" && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => --prev);
      }, 1000);
      if (countdown === 1) setIsResendEnabled(true);
      return () => clearInterval(timer);
    }
  }, [step, countdown]);

  const resetState = () => {
    setStep("number");
    setNumber("");
    setTermsChecked(false);
    setOtp("");
    setCountdown(20);
    setIsResendEnabled(false);
  };

  if (!show) {
    return null;
  }

  const handleSubmitNumber = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (termsChecked) {
        console.log(number);
        const res = await axios.post(
          `${process.env.REACT_APP_API_KEY}/api/login`,
          {
            phone_number: `+${number}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data && res.data.success) {
          toast.success(res.data.message);
          setStep("otp");
          setCountdown(20);
        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.error("You must agree to the terms and conditions.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Somehing went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/verify_otp`,
        {
          phone_number: `+${number}`,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = () => {
    if (isResendEnabled) {
      setCountdown(20);
      setIsResendEnabled(false);
      alert("OTP resent");
    }
  };

  return (
    <div className="login-dialog-overlay">
      <div className="login-dialog">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>{step === "number" ? "Login to continue" : "Enter OTP"}</h2>
        {step === "number" ? (
          <form onSubmit={handleSubmitNumber}>
            <div className="login-di">
              {/* <input
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              /> */}
              <PhoneInput
                country={"in"}
                value={number}
                onChange={setNumber}
                inputStyle={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "10px",
                  fontSize: "16px",
                  border: "1px solid #000",
                  borderLeftColor: "#000000",
                  borderBottomColor: "#000000",
                  borderLeftWidth: "4px",
                  borderBottomWidth: "4px",
                  marginTop: "0px",
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  speciallabel: "Enter",
                }}
              />
            </div>
            <div>
              <label style={{ fontSize: "0.8rem" }}>
                <input
                  style={{ marginRight: "5px" }}
                  type="checkbox"
                  checked={termsChecked}
                  onChange={(e) => setTermsChecked(e.target.checked)}
                />
                By continuing, I agree to the Terms of Use & Privacy Policy
              </label>
            </div>
            <div>
              <button type="submit" className="submit-button">
                {loading ? <LoginLoader /> : "Submit"}
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmitOtp}>
            <div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="resend-otp">
              Didn’t get the OTP?{" "}
              {isResendEnabled ? (
                <button onClick={handleResendOtp} className="resend-button">
                  Resend
                </button>
              ) : (
                `Resend in ${countdown}`
              )}
            </div>
            <div>
              <button type="submit" className="submit-button">
                {loading ? <LoginLoader /> : "Submit"}
              </button>
            </div>
          </form>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default LoginDialog;
