import React from "react";
import "../styles/InfluencerCard.css";
import { useNavigate } from "react-router-dom";

function InfluencerCard({ name, username, profilePicture }) {
  const navigate = useNavigate();
  const getInfluencer = () => {
    navigate("/instaposts", {
      state: {
        username: username,
        fullName: name,
        profilePicUrl: profilePicture,
      },
    });
  };

  return (
    <div>
      <div className="influencer-card" onClick={getInfluencer}>
        <div className="profile-picture">
          <img
            // src={`${
            //   process.env.REACT_APP_API_KEY
            // }/api/proxy-image?url=${encodeURIComponent(profilePicture)}`}
            src={profilePicture}
            alt={`${name}'s profile`}
          />
        </div>
        <div className="info">
          <h3>{name}</h3>
        </div>
      </div>
      <p className="username">@{username}</p>
    </div>
  );
}

export default InfluencerCard;
