import React, { useState } from "react";
import Navbar from "../components/Navbar";
import TopInfluencers from "../components/TopInfluencers";
import HowItWorks from "../components/Details";
import Footer from "../components/Footer";
import HeroBanner from "../components/HeroBanner";
import Card from "../components/Card";
import AddBanner from "../components/AddBanner";
import "../App.css";
import LoginDialog from "../components/LoginDialog";
import { useNavigate } from "react-router-dom";
import ProfileSideBar from "./ProfileSideBar";
import AlertBox from "./AlertBox";
import { useSetRecoilState } from "recoil";
import { loginState } from "../store/atoms/phoneNumber";

function HomePage() {
  const [showLogin, setShowLogin] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const setLogin = useSetRecoilState(loginState);

  const onLogout = () => {
    setAlert(true);
  };

  const onCancel = () => {
    setAlert(false);
  };

  const handleOpenLogin = () => setShowLogin(true);
  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const showProfileBar = () => {
    setShowProfile(true);
  };

  const hideProfileBar = () => {
    setShowProfile(false);
  };

  const handleSelectUser = (user) => {
    navigate("/instaposts", {
      state: {
        username: user.value,
        fullName: user.fullName,
        profilePicUrl: user.profilePicUrl,
      },
    });
  };

  const logOut = () => {
    localStorage.removeItem("auth");
    hideProfileBar();
    onCancel();
    setLogin(false);
  };

  return (
    <div className="App">
      <div className={`home-page ${showProfile ? "blurred" : ""}`}>
        <Navbar
          onOpenLogin={handleOpenLogin}
          onSelectUser={handleSelectUser}
          showProfileBar={showProfileBar}
        />
        <HeroBanner />
        <section className="influencers-section">
          <h2>Top Influencers & Handles</h2>
          <TopInfluencers />
        </section>
        <AddBanner />
        <section className="how-it-works-section">
          <HowItWorks />
        </section>
        <section className="how-it-works-section">
          <Card />
        </section>
        <Footer />
      </div>
      <LoginDialog show={showLogin} onClose={handleCloseLogin} />
      <ProfileSideBar
        show={showProfile}
        onClose={hideProfileBar}
        onLogout={onLogout}
      />
      <AlertBox show={alert} onCancel={onCancel} logOut={logOut} />
    </div>
  );
}

export default HomePage;
