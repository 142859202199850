import { atom, selector } from "recoil";

export const postsState = atom({
  key: "postsState",
  default: [],
});

export const endCursorState = atom({
  key: "endCursorState",
  default: "",
});

export const usernameState = atom({
  key: "usernameState",
  default: "",
});

export const resetPostsState = selector({
  key: "resetPostsState",
  get: ({ get }) => {
    return get(postsState);
  },
  set: ({ set }) => {
    set(postsState, []);
    set(endCursorState, "");
  },
});
