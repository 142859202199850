import React from "react";
import "../styles/CustomLoader.css";

const CustomLoader = ({ customClass }) => {
  return (
    <>
      <div className={`custom-loader-container ${customClass}`}>
        <div className="custom-loader"></div>
      </div>
    </>
  );
};

export default CustomLoader;
