import React from "react";
import Layout from "../components/Layouts/Layout";
import UploadCsv from "../components/UploadCsv";

const UploadCsvPage = () => {
  return (
    <Layout>
      <UploadCsv />
    </Layout>
  );
};

export default UploadCsvPage;
