import React from "react";
import "./App.css";
import ShowPosts from "./pages/ShowPosts";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import InstaPost from "./pages/InstaPost";
import ShowTrends from "./pages/ShowTrends";
import UploadCsvPage from "./pages/UploadCsvPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/showposts" element={<ShowPosts />} />
          <Route exact path="/instaposts/*" element={<InstaPost />} />
          <Route exact path="/show_trends" element={<ShowTrends />} />
          <Route exact path="/upload_csv" element={<UploadCsvPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
