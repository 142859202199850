import React from "react";

const Inputbox = ({
  label,
  icon,
  onChange,
  inputType,
  placeholder,
  value,
  readOnly,
}) => {
  return (
    <div className="flex flex-col mb-2">
      <div className="text-large font-aleo font-bold text-left py-2">
        {label}
      </div>
      <div
        className={`relative flex items-center border border-black rounded-lg py-2  ${
          readOnly ? "bg-greyCustom" : ""
        }`}
      >
        <img src={icon} alt="icon" className="absolute left-2 " />
        <input
          onChange={onChange}
          type={inputType}
          required
          placeholder={placeholder}
          className={`pl-8 py-2 w-full border-none outline-none ms-2 ${
            readOnly ? "bg-greyCustom" : ""
          }`}
          value={value}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default Inputbox;
