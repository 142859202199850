import React, { useState } from "react";
import Layout from "../components/Layouts/Layout";
import SuggestedProductView from "../components/SuggestedProductView";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ShowTrends = () => {
  const location = useLocation();
  const { im_url } = location.state || {};
  const [showLogin, setShowLogin] = useState(false);
  const handleOpenLogin = () => setShowLogin(true);
  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const isWebOrMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Layout
      onClose={handleCloseLogin}
      show={showLogin}
      onOpenLogin={handleOpenLogin}
      isWebOrMobile={isWebOrMobile}
    >
      <SuggestedProductView imUrl={im_url} handleOpenLogin={handleOpenLogin} />
    </Layout>
  );
};

export default ShowTrends;
