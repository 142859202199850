import React, { useCallback, useEffect, useState } from "react";
import InfluencerCard from "./InfluencerCard";
import "../styles/TopInfluencers.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../context/auth";
import { useSetRecoilState } from "recoil";
import { phoneNumberState } from "../store/atoms/phoneNumber";

function TopInfluencers() {
  const [influencers, setInfluencers] = useState([]);
  const [auth] = useAuth();
  const token = auth?.token || "";
  const setPhoneNumber = useSetRecoilState(phoneNumberState);

  const fetchInfluencers = useCallback(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/get_homepage`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.data.success) {
        setInfluencers(res.data.data[0].influencers);
        if (res.data.data[1]) {
          if (res.data.data[1].phone_number === "expired") {
            localStorage.removeItem("auth");
          } else {
            setPhoneNumber(res.data.data[1].phone_number);
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.error("Error fetching influencers:", error);
    }
  }, [token, setPhoneNumber]);

  useEffect(() => {
    fetchInfluencers();
  }, [fetchInfluencers]);
  return (
    <div className="top-influencers">
      {influencers.map((influencer, index) => (
        <InfluencerCard
          key={index}
          name={influencer.name}
          username={influencer.username}
          profilePicture={influencer.photo}
        />
      ))}
      <ToastContainer />
    </div>
  );
}

export default TopInfluencers;
