import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "../../App.css";
import LoginDialog from "../LoginDialog";
import InstaTopBar from "../../pages/InstaTopBar";
import { useLocation } from "react-router-dom";
import ProfileSideBar from "../../pages/ProfileSideBar";
import AlertBox from "../../pages/AlertBox";
import { useSetRecoilState } from "recoil";
import { loginState } from "../../store/atoms/phoneNumber";

const Layout = ({
  children,
  title,
  description,
  keywords,
  author,
  onSelectUser,
  onOpenLogin,
  onClose,
  show,
  isWebOrMobile,
  username,
  profilePic,
  name,
}) => {
  // const [showLogin, setShowLogin] = useState(false);
  // const handleOpenLogin = () => setShowLogin(true);
  // const handleCloseLogin = () => {
  //   setShowLogin(false);
  // };
  const [showProfile, setShowProfile] = useState(false);
  const [alert, setAlert] = useState(false);
  const setLogin = useSetRecoilState(loginState);

  const onLogout = () => {
    setAlert(true);
  };

  const onCancel = () => {
    setAlert(false);
  };

  const showProfileBar = () => {
    setShowProfile(true);
  };

  const hideProfileBar = () => {
    setShowProfile(false);
  };

  const logOut = () => {
    localStorage.removeItem("auth");
    hideProfileBar();
    onCancel();
    setLogin(false);
  };

  const location = useLocation();
  const isProductPage =
    location.pathname.includes("product") ||
    location.pathname.includes("show_trends");

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      {isWebOrMobile && !isProductPage && (
        <InstaTopBar profile_pic={profilePic} name={name} username={username} />
      )}
      {!isWebOrMobile && (
        <Navbar
          onOpenLogin={onOpenLogin}
          onSelectUser={onSelectUser}
          showProfileBar={showProfileBar}
        />
      )}
      <main style={{ minHeight: "100vh" }}>
        <Toaster />
        {children}
      </main>
      <Footer />
      <LoginDialog show={show} onClose={onClose} />
      <ProfileSideBar
        show={showProfile}
        onClose={hideProfileBar}
        onLogout={onLogout}
      />
      <AlertBox show={alert} onCancel={onCancel} logOut={logOut} />
    </div>
  );
};

Layout.defaultProps = {
  title: "Slayyt - Shop Now",
  description: "Shop trends",
  keywords: "instagram,trends",
  author: "Navpreet Singh",
};

export default Layout;
