import React from "react";
import "../styles/Footer.css";
import instaIcon from "../assets/insta.svg";
import fbIcon from "../assets/fb.svg";
import twitterIcon from "../assets/twitter.svg";
import snapchat from "../assets/snapchat.svg";
import youtube from "../assets/youtube.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        <div>Terms & Conditions</div>
      </div>
      <div className="footer-center">
        <div>&copy; {new Date().getFullYear()}</div>
      </div>
      <div className="footer-right">
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instaIcon} alt="Instagram" />
        </a>
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={fbIcon} alt="Facebook" />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitterIcon} alt="Twitter" />
        </a>
        <a
          href="https://www.snapchat.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={snapchat} alt="Twitter" />
        </a>
        <a
          href="https://www.youtube.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtube} alt="Twitter" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
