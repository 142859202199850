import React, { useEffect, useState, useCallback } from "react";
import "../styles/InstaPostsView.css";
import searchIcon from "../assets/insta-search.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  endCursorState,
  postsState,
  resetPostsState,
  usernameState,
} from "../store/atoms/posts";
import CustomLoader from "./CustomLoader";
import { useMediaQuery } from "react-responsive";
import ShopHeading from "./ShopHeading";

const InstaPostsView = ({ username, fullName, profilePicUrl }) => {
  const count = 24;
  const navigate = useNavigate();
  const [posts, setPosts] = useRecoilState(postsState);
  const [endCursor, setEndCursor] = useRecoilState(endCursorState);
  const resetPosts = useSetRecoilState(resetPostsState);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [userName, setUserName] = useRecoilState(usernameState);
  const [scrollLoading, setScrollLoading] = useState(false);
  const isWebOrMobile = useMediaQuery({ maxWidth: 767 });

  const fetchPosts = useCallback(
    async (username, endCursor = "") => {
      try {
        if (endCursor) {
          setScrollLoading(true);
        } else {
          setLoading(true);
        }
        const res = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/search_insta_user_posts?username=${username}&count=${count}&end_cursor=${endCursor}&profile_pic=${profilePicUrl}`
        );
        if (res.data) {
          setPosts((prevPosts) => [...prevPosts, ...res.data.data]);
          setEndCursor(res.data.pageInfo.end_cursor);
          setUserName(username);
          console.log(res.data.data);
          console.log(res.data.pageInfo.end_cursor);
        }
      } catch (error) {
        toast.error("Something Went Wrong!");
        console.log(error);
      } finally {
        setLoading(false);
        setScrollLoading(false);
      }
    },
    [count, profilePicUrl, setPosts, setEndCursor, setUserName]
  );

  useEffect(() => {
    if (userName !== username) {
      resetPosts();
      fetchPosts(username);
    }
  }, [username, fetchPosts, resetPosts, userName]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading
    )
      return;

    if (endCursor) {
      fetchPosts(username, endCursor);
    }
  }, [loading, endCursor, fetchPosts, username]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const getImage = (src) => {
    console.log(src);
    navigate("product", {
      state: {
        img_url: src,
        username: username,
        fullName: fullName,
        profilePicUrl: profilePicUrl,
      },
    });
  };

  const getClassName = (index) => {
    const itemsPerRow = 6;
    const row = Math.floor(index / itemsPerRow);
    const column = index % itemsPerRow;
    return row % 2 === 0
      ? column % 2 === 0
        ? "even-image"
        : "odd-image"
      : column % 2 === 0
      ? "odd-image"
      : "even-image";
  };

  const getClassNameOfDiv = (index) => {
    const itemsPerRow = 6;
    const row = Math.floor(index / itemsPerRow);
    const column = index % itemsPerRow;
    return row % 2 === 0
      ? column % 2 === 0
        ? "image-wrapper-even"
        : "image-wrapper-odd"
      : column % 2 === 0
      ? "image-wrapper-odd"
      : "image-wrapper-even";
  };

  const renderSkeletonLoaders = () => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <div className={getClassNameOfDiv(i)} key={i}>
          <Skeleton height={190} width={190} />
        </div>
      );
    }
    return skeletons;
  };

  return (
    <div className="posts-container-insta">
      {isWebOrMobile ? (
        imageLoading ? (
          <Skeleton count={2} width={300} />
        ) : (
          <ShopHeading />
        )
      ) : imageLoading ? (
        <Skeleton count={1} width={100} />
      ) : (
        <h4>Top Picks</h4>
      )}
      <div className="insta-container">
        {loading
          ? renderSkeletonLoaders()
          : posts.length > 0
          ? posts.map((ele, index) => {
              return (
                <div className={getClassNameOfDiv(index)} key={index}>
                  {imageLoading && <Skeleton height={190} width={190} />}
                  <img
                    src={`${
                      process.env.REACT_APP_API_KEY
                    }/api/proxy-image?url=${encodeURIComponent(ele)}`}
                    alt="img"
                    className={getClassName(index)}
                    onLoad={() => setImageLoading(false)}
                    onClick={(e) => {
                      getImage(e.target.src);
                    }}
                    style={{ display: imageLoading ? "none" : "block" }}
                  />
                  <button className="magnifying-glass">
                    <img src={searchIcon} alt="search-img" />
                  </button>
                </div>
              );
            })
          : null}
        {scrollLoading && <CustomLoader />}
      </div>
      <ToastContainer />
    </div>
  );
};

export default InstaPostsView;
