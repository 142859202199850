import React from "react";
import "../styles/Details.css";

// const steps = [
//     { title: 'Lorem Ornare', description: 'Duis a augue eu lectus congue luctus. Vivamus eu lacus vestibulum, luctus ante dignissim, interdum' },
//     { title: 'Parturient Malesuada Sem', description: 'Duis a augue eu lectus congue luctus. Vivamus eu lacus vestibulum, luctus ante dignissim, interdum' },
//     { title: 'Inceptos', description: 'Duis a augue eu lectus congue luctus. Vivamus eu lacus vestibulum, luctus ante dignissim, interdum' },
// ];

function Details() {
  return (
    <div className="how-it-works">
      <h2>How it works?</h2>
    </div>
  );
}

export default Details;
