import React from "react";

const ShopHeading = () => {
  return (
    <div className="flex flex-col justify-center w-full mt-2 items-center">
      <div className="text-3xl font-bold font-aleo text-customColor ">
        Shop Instagram Looks
      </div>
      <div className="font-normal	font-aleo text-customColor text-sm mt-1 mb-4">
        Style like your favourite influncer
      </div>
    </div>
  );
};

export default ShopHeading;
