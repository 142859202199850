import React, { useEffect } from "react";
import "../styles/AlertBox.css";

const AlertBox = ({ show, onCancel, logOut }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  if (!show) return null;
  return (
    <div className="alert-box-overlay">
      <div className="alert-box">
        <div className="alert-title">Logout</div>
        <div className="alert-message">Are you sure you want to logout?</div>
        <div className="alert-btns">
          <button className="cancel-btn" onClick={onCancel}>
            Cancel
          </button>
          <button className="logout-btn" onClick={logOut}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertBox;
