import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useState,
  Suspense,
  useRef,
} from "react";
import SuggestedProduct from "./SuggestedProduct";
import "../styles/SuggestedProductView.css";
// import MainLoader from "./MainLoader";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import LazyImage from "./LazyImage";
// import DotLoader from "./DotLoader";
import imageCompression from "browser-image-compression";
// import CustomLoader from "./CustomLoader";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SuggestedProductView = ({ imUrl, handleOpenLogin }) => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageUrl, setImageUrl] = useState("");
  const [imageHash, setImageHash] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [productLoader, setProductLoader] = useState(false);
  const navigate = useNavigate();
  const isWebOrMobile = useMediaQuery({ maxWidth: 767 });
  const refListMain = useRef();
  const refProductList = useRef();

  // eslint-disable-next-line
  const [gender, setGender] = useState(null);

  const isBase64 = (str) => str.includes("base64");

  // const base64ToImageUrl = (base64) => {
  //   try {
  //     // Remove any extra metadata from the base64 string if present
  //     const base64Data = base64.split(",")[1] || base64;

  //     const byteCharacters = atob(base64Data);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: "image/png" });
  //     return URL.createObjectURL(blob);
  //   } catch (error) {
  //     console.error("Failed to decode Base64 string:", error);
  //     return null; // Return null or a default image URL in case of an error
  //   }
  // };

  const base64ToFile = (base64, filename) => {
    try {
      const byteString = atob(base64.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: "image/png" });
      return new File([blob], filename, { type: "image/png" });
    } catch (error) {
      console.error("Failed to decode Base64 string:", error);
      return base64;
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Image compression failed:", error);
      return file;
    }
  };

  const uploadToBackend = useCallback(async (base64Image) => {
    try {
      const file = base64ToFile(base64Image, "image.png");
      const compressedFile = await compressImage(file);
      const formData = new FormData();
      formData.append("image", compressedFile);
      const isEmpty = ![...formData.entries()].length;
      if (isEmpty) {
        toast.error("Something went wrong!");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/upload_image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log(response.data);
        setImageHash(response.data.deletehash);
        return response.data.url;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Image upload failed:", error);
      return null;
    }
  }, []);

  const deleteHash = useCallback(async () => {
    if (!isDeleted) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_KEY}/api/delete_image`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              image_hash: imageHash,
            },
          }
        );
        if (response.data.success) {
          console.log(response.data.message);
          setIsDeleted(true);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [imageHash, isDeleted]);

  // const getGender = useCallback(async () => {
  //   try {
  //     if (imageUrl) {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_KEY}/api/v1/gender_detection`,
  //         {
  //           image_url: imageUrl,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.data.success) {
  //         setGender(response.data.data[0]);
  //       } else {
  //         console.log(response.data.message);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [imageUrl, setGender]);

  useEffect(() => {
    if (isBase64(imUrl)) {
      uploadToBackend(imUrl)
        .then((url) => {
          if (url) {
            console.log(url);
            setImageUrl(url);
            setIsImageLoading(false);
          }
        })
        .catch((error) => {
          console.error("Image upload failed:", error);
        });
    } else {
      setImageUrl(
        `${
          process.env.REACT_APP_API_KEY
        }/api/proxy-image?url=${encodeURIComponent(imUrl)}`
      );
      setIsImageLoading(false);
    }
  }, [imUrl, uploadToBackend]);

  const fetchProducts = useCallback(async () => {
    let imageQuery;
    if (imUrl && !isBase64(imUrl)) {
      imageQuery = imUrl.split("=")[1];
    } else {
      imageQuery = imageUrl;
    }
    console.log("imageQuery", imageQuery);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/v1/vector_image_search`,
        {
          img_url: imageQuery,
          page: currentPage,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data);
      if (res.data.success) {
        console.log(res.data.response);
        setProducts((prevProducts) => [...prevProducts, ...res.data.response]);
        if (res.data.response.length === 0) {
          setProductLoader(true);
        }
      }
    } catch (error) {
      // toast.error("Something went wrong!");
      console.log(error);
    }
  }, [currentPage, imUrl, imageUrl]);

  useEffect(() => {
    if (imageUrl) {
      fetchProducts();
    }
  }, [imageUrl, fetchProducts]);

  // eslint-disable-next-line
  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (!isDeleted && isBase64(imUrl)) {
        deleteHash();
      }
      setCurrentPage((prevPage) => prevPage + 1);
      fetchProducts();
    }
  };

  useEffect(() => {
    const handleMobileScroll = () => {
      if (isWebOrMobile) {
        const productList = refProductList.current;
        const listMain = refListMain.current;
        if (
          window.document.body.offsetHeight - window.scrollY - 2 <=
          window.innerHeight
        ) {
          productList.style.overflowY = "scroll";
          listMain.style.overflowY = "hidden";
        } else {
          listMain.style.overflowY = "scroll";
        }
        //  else {
        //   productList.style.overflowY = "hidden";
        // }
      }
    };

    window.addEventListener("scroll", handleMobileScroll);

    return () => {
      window.removeEventListener("scroll", handleMobileScroll);
    };
  }, [isWebOrMobile]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll, deleteHash]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const renderSkeletonLoaders = () => {
    let count = isWebOrMobile ? 4 : 8;
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      if (isWebOrMobile && i >= 2) {
        skeletons.push(<Skeleton height={210} width={164} />);
      } else {
        skeletons.push(<Skeleton height={210} width={164} />);
      }
    }
    return skeletons;
  };

  return (
    <div className="product-main">
      <div className="product-preview">
        <Suspense fallback={<Skeleton width={420} />}>
          {!isImageLoading ? (
            <LazyImage src={imageUrl ? imageUrl : imUrl} alt="productImg" />
          ) : (
            <Skeleton width={420} height={420} />
          )}
        </Suspense>
        {/* <img src={imageUrl} alt="productImg" /> */}
        {!isImageLoading ? (
          <button className="back-button-product" onClick={handleBackClick}>
            <span>&lt;</span>
          </button>
        ) : (
          <i />
        )}
      </div>
      <div className="product-list-main" ref={refListMain}>
        <h4>Suggested Products</h4>
        {/* <div className="flex justify-between items-center">
          <div className="flex items-center justify-center p-4">
            <button className="flex flex-row item-center justify-center">
              Filter
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
        </div> */}
        <div className="product-list" ref={refProductList}>
          {products.length > 0 ? (
            products.map((product, index) => {
              const decodedPrice = decodeURIComponent(product.price);
              return (
                <SuggestedProduct
                  key={index}
                  // product_image={product.main_image_url}
                  // title={product.data.title}
                  // price={`${product.data.price.currency} ${product.data.price.value}`}
                  // product_url={product.data.product_url}
                  product_image={product.image}
                  title={product.product}
                  price={decodedPrice}
                  product_url={product.url}
                  handleOpenLogin={handleOpenLogin}
                />
              );
            })
          ) : productLoader ? (
            <div className="no-product">
              <div className="no-product-message">No match found</div>
            </div>
          ) : (
            renderSkeletonLoaders()
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SuggestedProductView;
