import React, { useState, useEffect } from "react";
import "../styles/ShowPosts.css";

const ShowPosts = () => {
  const [instagramData, setInstagramData] = useState(null);

  useEffect(() => {
    // Check if the Facebook SDK is loaded
    // if (window.FB) {
    //   // Make sure the user is logged in and authenticated
    //   window.FB.getLoginStatus(function (response) {
    //     if (response.status === "connected") {
    //       fetchInstagramData(response.authResponse.accessToken);
    //     } else {
    //       // Prompt the user to log in
    //       window.FB.login(
    //         function (response) {
    //           if (response.authResponse) {
    //             fetchInstagramData(response.authResponse.accessToken);
    //           }
    //         },
    //         {
    //           scope:
    //             "pages_show_list,instagram_basic,instagram_manage_insights",
    //         }
    //       );
    //     }
    //   });
    // }
    fetchInstagramData();
  }, []);

  const fetchInstagramData = () => {
    window.FB.api(
      "/me/accounts",
      "GET",
      {
        fields:
          "instagram_business_account{id,name,username,profile_picture_url,media{comments_count,like_count,media_url}}",
        access_token:
          "EAA2TFXuet8EBO4Ucqu7cDOJFgD6ecS2JE4FSneEDBHGiUVBFSOLdqgLbuglwTPKN1zQ7Agtn4DAkvOuNAd0ZBXqUG4hrEmon1DEHQyTnkczwrwGkLM5ZB4wQPfMESNWwcOYrqOT3nf6QNvSHGYqGbssQTdkI0zkbOpwi1bj8M8cQzAHQyLch3ZAYqJQrs7wqKZBx2slQd79MmiaMK6ZCfhpSLMwZDZD",
      },
      function (response) {
        console.log(response);
        if (response && !response.error) {
          setInstagramData(response.data);
        } else {
          console.error("Error fetching data:", response.error);
        }
      }
    );
  };

  return (
    <div className="container">
      <h1>Instagram Business Account Data</h1>
      {instagramData ? (
        <div>
          {instagramData.map((account) => (
            <div
              key={account.instagram_business_account.id}
              className="account"
            >
              <h2>
                {account.instagram_business_account.name} (@
                {account.instagram_business_account.username})
              </h2>
              <img
                src={account.instagram_business_account.profile_picture_url}
                alt={account.instagram_business_account.username}
                className="profile-profile-picture_new"
              />
              <div className="media-grid">
                {account.instagram_business_account.media.data.map((media) => (
                  <div key={media.id} className="media-item">
                    <img src={media.media_url} alt="media" />
                    <p>Likes: {media.like_count}</p>
                    <p>Comments: {media.comments_count}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ShowPosts;
