import React from "react";
import "../styles/DotLoader.css";

const DotLoader = () => {
  return (
    <div className="dot-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default DotLoader;
