import React from "react";
import "../styles/SaveLoader.css";

const SaveLoader = () => {
  return (
    <div className="save-loader">
      <i />
    </div>
  );
};

export default SaveLoader;
