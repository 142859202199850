import React, { useEffect, useRef } from "react";
import "../styles/InstaProfileCard.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const InstaProfileCard = ({ profile_image, name, username }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const profileImageRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const getDimensions = () => ({
      width: profileImageRef.current.offsetWidth,
      height: profileImageRef.current.offsetHeight,
    });
    setImageDimensions(getDimensions());
  }, [imageLoading]);

  return (
    <div className="profile-card">
      <div className="arrow" onClick={() => navigate(-1)}>
        <span>&lt;</span>
      </div>
      <div className="profile-image" ref={profileImageRef}>
        {imageLoading && (
          <Skeleton
            circle={true}
            width={imageDimensions.width}
            height={imageDimensions.height}
          />
        )}
        <img
          src={`${
            process.env.REACT_APP_API_KEY
          }/api/proxy-image?url=${encodeURIComponent(profile_image)}`}
          alt="profileImage"
          onLoad={() => setImageLoading(false)}
        />
      </div>
      <div className="profile-details">
        {imageLoading ? <Skeleton count={1} width={120} /> : <h2>{name}</h2>}
        {imageLoading ? <Skeleton count={1} width={120} /> : <p>@{username}</p>}
      </div>
    </div>
  );
};

export default InstaProfileCard;
