import React, { useState } from "react";
import InstaProfileCard from "../components/InstaProfileCard";
import Layout from "../components/Layouts/Layout";
import InstaPostsView from "../components/InstaPostsView";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SuggestedProductView from "../components/SuggestedProductView";
import { useMediaQuery } from "react-responsive";

const InstaPost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const { username, fullName, profilePicUrl, img_url } = location.state || {};
  const isWebOrMobile = useMediaQuery({ maxWidth: 767 });

  const handleOpenLogin = () => setShowLogin(true);
  const handleCloseLogin = () => {
    setShowLogin(false);
  };
  // const { img_url } = location.state;
  const handleSelectUser = (user) => {
    navigate("/instaposts", {
      state: {
        username: user.value,
        fullName: user.fullName,
        profilePicUrl: user.profilePicUrl,
      },
    });
  };
  return (
    <Layout
      title={"Slayyt"}
      onSelectUser={handleSelectUser}
      onClose={handleCloseLogin}
      show={showLogin}
      onOpenLogin={handleOpenLogin}
      isWebOrMobile={isWebOrMobile}
      profilePic={profilePicUrl}
      name={fullName}
      username={username}
    >
      {!isWebOrMobile && (
        <InstaProfileCard
          profile_image={profilePicUrl}
          name={fullName}
          username={username}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <InstaPostsView
              username={username}
              fullName={fullName}
              profilePicUrl={profilePicUrl}
            />
          }
        />
        <Route
          path="product"
          element={
            <SuggestedProductView
              imUrl={img_url}
              handleOpenLogin={handleOpenLogin}
            />
          }
        />
      </Routes>
    </Layout>
  );
};

export default InstaPost;
