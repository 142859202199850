import React from "react";
import "../styles/Loader.css";

const Loader = () => (
  <div className="loader">
    <i />
  </div>
);

export default Loader;
