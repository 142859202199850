import React from "react";
import redirectionIcon from "../assets/redirect_arrow.svg";
import "../styles/SuggestedProduct.css";
import { useAuth } from "../context/auth";
import axios from "axios";

const SuggestedProduct = ({
  product_image,
  title,
  price,
  brand,
  product_url,
  handleOpenLogin,
}) => {
  const auth = useAuth();
  const handleDivClick = async () => {
    if (!auth[0].token) {
      handleOpenLogin();
    } else {
      const fetchedLink = await fetchAffiliateUrl();
      if (fetchedLink)
        window.open(fetchedLink, "_blank", "noopener noreferrer");
    }
  };

  const fetchAffiliateUrl = async () => {
    try {
      if (product_url) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/v1/get_link?affiliate_link=${product_url}`
        );
        if (response.data.success) {
          const affiliateUrl = response.data.data.optimized || product_url;
          return affiliateUrl;
        } else {
          console.log(response.data.message);
          return product_url;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div className="suggested" onClick={handleDivClick}>
      <div className="image-wrapper-suggested ">
        <img
          src={product_image}
          alt="product_image"
          className="image-wrapper-suggested-image"
          onClick={(e) => e.preventDefault()}
        />
        <a href={product_url} target="_blank" rel="noopener noreferrer">
          <i />
        </a>
        <button className="redirection-arrow" onClick={handleButtonClick}>
          <img src={redirectionIcon} alt="redirectionIcon" />
        </button>
      </div>
      <div className="suggested-info">
        {/* <p>{brand}</p> */}
        <p className="title-p">{title}</p>
        <p className="price-p">{price}</p>
      </div>
    </div>
  );
};

export default SuggestedProduct;
